<template>
  <div class="row">
    <div class="col-12">
      <sgv-input-select
        :options="options"
        v-model="selected"
        select="value">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-select>
    </div>

    <div class="col-12 mb-3">
      <div
        class="dropbox"
        @click="$refs.fileInput.click()">
        <p class="text-success">
          <slot>เพิ่มไฟล์</slot>
        </p>
        <input
          type="file"
          ref="fileInput"
          class="input-file"
          multiple
          @change="onFileChange"/>
      </div>
    </div>

    <div class="col-12">
      <div class="row justify-content-start">
        <div
          class="col-6 col-sm-4 col-md-3"
          v-for="item in items"
          :key="item.attachmentId">
          <img :src="item.src" class="img-fluid" alt="...">
          <div style="word-wrap: break-word;">
            {{item.filename}}
          </div>
          <div class="mt-1 mb-4">
            <span
              @click="getFilename(item)"
              style="font-size: 20px;"
              class="mr-2">
              <fa icon="copy" class="text-primary pointer"></fa>
            </span>

            <span
              @click="getMarkdown(item)"
              style="font-size: 20px;"
              class="mr-2">
              <fa :icon="['fab', 'markdown']" class="text-warning pointer"></fa>
            </span>

            <span
              style="font-size: 20px;"
              class="mr-2"
              @click="getLink(item)">
              <fa icon="link" class="text-info pointer"></fa>
            </span>

            <span
              style="font-size: 20px;"
              class="mr-2"
              @click="download(item)">
              <fa icon="download" class="text-primary pointer"></fa>
            </span>

            <span style="font-size: 20px;" class="float-right">
              <fa
                icon="trash"
                class="text-danger pointer"
                @dblclick="deleteObject(item)">
              </fa>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Compressor from 'compressorjs'
import {
  LIST_ATTACHMENT,
  DETAIL_ATTACHMENT,
  CREATE_ATTACHMENT,
  DESTROY_ATTACHMENT,
} from './graph/attachment'

export default {
  name: 'AssetAttachment',
  props: {
    assetId: {
      type: Number,
      required: true
    },
    assetType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    attachments: {
      type: Array,
      required: true
    },
    isPublic: {
      type: Boolean,
      required: false
    },
    isCompress: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      items: [],
      accept: 'image/*',
      selected: 'etc'
    }
  },
  computed: {
    options () {
      return this.attachments
      .filter(v => {
        if (!v.role) return true

        return this.$auth.hasRole(v.role)
      })
    },
  },
  methods: {
    fetchList () {
      this.$apollo.query({
        query: LIST_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          assetId: this.assetId
        },
        fetchPolicy: 'network-only'
      }).then(res => {
        const arr = res.data.items.map(async (item) => {
          const src = await this.fetchCover(item)
          return {
            attachmentId: item.id,
            filename: item.filename,
            src
          }
        })
        Promise.all(arr).then(v => {
          this.items = v
        })
      })
      .catch(err => {
        this.$toasted.error(err)
      })
    },
    fetchCover (item) {
      let extension = item.filename.split('.').pop()
      if (['jpg', 'jpeg', 'png', 'svg'].includes(extension.toLowerCase())) {
        return this.$apollo.query({
          query: DETAIL_ATTACHMENT(this.templateType),
          variables: {
            assetType: this.assetType,
            type: this.selected,
            attachmentId: item.id
          },
          fetchPolicy: 'network-only'
        })
        .then(res => res.data.url)
        .catch(() => '')
      }
    },
    getFilename (item) {
      this.$copyText(item.filename)
    },
    getLink (item) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          attachmentId: item.attachmentId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.$copyText(res.data.url)
      })
      .catch(() => '')
    },
    getLine (item) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          attachmentId: item.attachmentId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const url = res.data.url
        const a = window.document.createElement('a')
        a.href = `https://line.me/R/msg/text/?${url}`
        a.click()
      })
      .catch(() => '')
    },
    getMarkdown (item) {
      this.$copyText(`![ชื่อรูปภาพ](${item.filename})`)
    },
    deleteObject (item) {
      this.$apollo.mutate({
        mutation: DESTROY_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          attachmentId: item.attachmentId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        const index = this.items.findIndex(v => v.attachmentId === item.attachmentId)
        if (index !== -1) this.items.splice(index, 1)
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
    onFileChange (e) {
      var fileInput = e.target.files
      this.compressFile(fileInput)
    },
    compressFile (files) {
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let file = files[key]
          let type = file.type
          let vm = this

          if (['image/jpeg', 'image/jpg', 'image/png'].includes(type) && file.size >= 200000 && this.isCompress) {
            console.log('compressing file...');
            new Compressor(file, {
              quality: 0.4,
              convertSize: 400000,
              maxHeight: 500,
              success(res) {
                vm.upload(res)
              }
            })
          } else {
            vm.upload(file)
          }
        }
      }
    },
    checkType (files) {
      let arr = []
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let inputAccept = this.accept.split(',')
          let typeAccept = files[key].type.split('/')
          let match = inputAccept.some(v => {
            let app = v.split('/')
            if(app[0].trim() !== typeAccept[0].trim()) return false
            if(app[1].trim() == '*') return true
            if(app[1].trim() !== typeAccept[1].trim()) return false
            return true
          })
          arr.push(match)
        }
      }
      return arr.every(v => v)
    },
    upload (file) {
      this.$apollo.mutate({
        mutation: CREATE_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          assetId: this.assetId,
          input: {filename: file.name}
        }
      })
      .then(res => {
        const config = res.data.createItem
        let formData = new FormData()

        formData.append('acl', this.isPublic ? 'public-read' : 'private')
        formData.append('Content-Type', file.type)
        Object.keys(config.fields).forEach(k => {
          formData.append(k, config.fields[k])
        })
        formData.append('file', file, file.name);

        axios.post(config.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.$toasted.global.success("เพิ่มสำเร็จ")
          this.fetchList()
        }).catch(() => {
          this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
        })
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
      })
    },
    download (item) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          assetType: this.assetType,
          type: this.selected,
          attachmentId: item.attachmentId,
          isDownload: true
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        return axios.get(res.data.url, {responseType: 'blob'})
      })
      .then(res => {
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = item.filename
        a.click()
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
  },
  watch: {
    selected () {
      this.fetchList()
    }
  },
  created() {
    this.fetchList()
  }
}
</script>

<style lang="css" scoped>
.image-width {
  width: 150px;
}

.dropbox {
  outline: 3px dashed grey; /* the dash box */
  outline-offset: -5px;
  color: dimgray;
  position: relative;
  cursor: pointer;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 15px;
  margin: auto;
}

.input-file {
  opacity: 1; /* invisible but it's there! */
  width: 0px;
  height: 0px;
  position: absolute;
  cursor: pointer;
  visibility: hidden;
}
</style>
